<template>
 <footer class="footer center"> <div class="footer__footer-content"><div class=footer__logo><router-link to=/ ><img alt="Логотип Климат Комплекс"class=footer__logo-icon src=../assets/image/gallery/logo2.png></router-link></div><nav class=footer__menu-links><router-link to=/ class=footer__menu-link>Главная страница</router-link><router-link to=/catalogHomeCondir class=footer__menu-link  >Кондиционеры для Квартиры и Дома</router-link><router-link to=/catalogInvertCondir class=footer__menu-link
        >Инверторные кондиционеры</router-link
      ><router-link to=/services class=footer__menu-link>Наши услуги</router-link></nav><div class=footer__contacts><h3 class=footer__contacts-text>Мы с вами на связи!</h3><div class=footer__phone-box><div class=footer__number-phone>+7 900 077-07-19</div></div><div class=footer__phone-box><div class=footer__number-phone>+7 900 080-12-87</div></div></div><div class=footer__socialNetwork><h3 class=footer__vk-text>Присоединяйтесь к нам: </h3><div class=footer__socialNetwork-link><div class=footer__socialNetwork-linkOne><a href=https://vk.com/id719500584><img alt=""class=footer__icon src=../assets/image/vk.svg> </a></div><div class=footer__socialNetwork-linkTwo><a href=https://t.me/+qXqgOu_vvIpiY2Yy><img alt=""class=footer__icon src=../assets/image/iconTelegram.svg> </a></div></div><h3 class=footer__copyright>© «Климат Комплекс», 2024</h3></div></div>
 </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
