<template>
  <header class="header">
    <div class="navigation">
      <input type="checkbox" class="navigation__toggle" id="nav-toggle" />
      <label for="nav-toggle" class="navigation__button"
        ><span class="navigation__icon"></span
      ></label>
      <div class="navigation__background"></div>

      <nav class="navigation__nav">
        <ul class="navigation__menu-links">
          <li>
            <router-link to="/" class="navigation__menu-link"
              >ГЛАВНАЯ</router-link
            >
          </li>
          <li>
            <router-link to="/catalogHomeCondir" class="navigation__menu-link"
              >Кондиционеры для Квартиры и Дома
            </router-link>
          </li>
          <li>
            <router-link to="/catalogInvertCondir" class="navigation__menu-link"
              >Инверторные кондиционеры
            </router-link>
          </li>
          <li>
            <router-link to="/services" class="navigation__menu-link"
              >НАШИ УСЛУГИ
            </router-link>
          </li>
        </ul>
        <div class="navigation__contacts">
          <div class="navigation__phone-box">
            <img
              class="navigation__phone-icon"
              src="../assets/image/phone.svg"
              alt="icon-phone"
            />
            <span class="navigation__number-phone">+7 900 077-07-19</span>
          </div>
          <div class="navigation__phone-box">
            <img
              class="navigation__phone-icon"
              src="../assets/image/phone.svg"
              alt="icon-phone"
            />
            <span class="navigation__number-phone">+7 900 080-12-87</span>
          </div>
        </div>
      </nav>
    </div>
    <div class="header__header-content center">
      <div class="header__logo">
        <router-link to="/">
          <img
            alt="Логотип Климат Комплекс"
            class="header__logo-image"
            src="../assets/image/gallery/logo1.png"
          />
        </router-link>
      </div>
      <nav class="menu">
        <div class="menu__menu-links">
          <router-link to="/" class="menu__menu-link">ГЛАВНАЯ</router-link>
          <div
            class="menu__dropdown"
            @mouseenter="showDropdown = true"
            @mouseleave="showDropdown = false"
          >
            <div class="menu__menu-link">
              КАТАЛОГ
              <span class="menu__angledown"></span>
            </div>
            <div class="menu__dropdown-link" v-if="showDropdown">
              <router-link
                to="/catalogHomeCondir"
                class="menu__catalog-menu-link"
                >Кондиционеры для Квартиры и Дома</router-link
              >
              <router-link
                to="/catalogInvertCondir"
                class="menu__catalog-menu-link"
                >Инверторные кондиционеры</router-link
              >
            </div>
          </div>
          <router-link to="/services" class="menu__menu-link"
            >УСЛУГИ
          </router-link>
        </div>
        <div class="contacts">
          <div class="contacts__phone-box">
            <img
              class="navigation__phone-icon"
              src="../assets/image/phone.svg"
              alt="icon-phone"
            />
            <div class="contacts__number-phone">+7 900 077-07-19</div>
            <div class="contacts__number-phone">+7 900 080-12-87</div>
          </div>
          <div class="contacts__socialNetwork">
            <div class="contacts__socialNetwork-link">
              <div class="social-linkOne">
                <a href="https://vk.com/id719500584">
                <img
                  class="contacts__icons"
                  src="../assets/image/vk.svg"
                  alt="socialNetwork"
                />
              </a>
              </div>
              <div class="social-linkTwo">
                 <a href=https://t.me/+qXqgOu_vvIpiY2Yy>
                <img alt="socialNetwork" class=contacts__icons
                 src=../assets/image/iconTelegram.svg>
                 </a>
              </div>
             
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
